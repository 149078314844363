import React from 'react';
import {RoutePermittedRole} from '../../shared/constants/AppEnums';

const ManageUser = React.lazy(() => import('./ManageUser'))
const ManageRole = React.lazy(() => import('./ManageRole'))
const Permission = React.lazy(() => import('./Permission'))

export const securityPageConfigs = [
  {
    permittedRole: RoutePermittedRole.user,
    path: '/security/manageuser',
    element: <ManageUser />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/security/managerole',
    element: <ManageRole />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/security/permission',
    element: <Permission />,
  },
];
