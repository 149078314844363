import {errorPagesConfigs} from './errorPages';
import {authRouteConfig} from './auth';
import {initialUrl} from '../shared/constants/AppConst';
import Error403 from './errorPages/Error403';
import React from 'react';
import Error404 from './errorPages/Error404';
import {profileConfig} from './profile';
import { dashboardPageConfigs } from './dashboard';
import { securityPageConfigs } from './security';
import { masterPageConfigs } from './master';
import { warehousePageConfigs } from './warehouse'
import { qcPageConfigs } from './qc';
import { productionPageConfigs } from './production';

const authorizedStructure = {
  fallbackPath: '/signin',
  unAuthorizedComponent: <Error403 />,
  routes: [...productionPageConfigs, ...qcPageConfigs, ...warehousePageConfigs ,...dashboardPageConfigs, ...securityPageConfigs, ...masterPageConfigs, ...profileConfig],
};

const unAuthorizedStructure = {
  fallbackPath: initialUrl,
  routes: authRouteConfig,
};

const anonymousStructure = {
  routes: errorPagesConfigs.concat([
    {
      path: '*',
      element: <Error404 />,
    },
  ]),
};

export {authorizedStructure, unAuthorizedStructure, anonymousStructure};
