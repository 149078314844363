import React from 'react';
import {Layout} from 'antd';
import './index.style.less';
import AppLogo from '../components/AppLogo';
import PropTypes from 'prop-types';
import {AiOutlineMenu} from 'react-icons/ai';
import HeaderUser from '../components/HeaderUser';

const AppHeader = ({isCollapsed, onToggleSidebar}) => {
  const {Header} = Layout;

  return (
    <Header className='app-header' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <a className='trigger' onClick={() => onToggleSidebar(!isCollapsed)}>
          <AiOutlineMenu />
        </a>
        <AppLogo />
      </div>
      <div className='app-header-sectionDesktop'>
        <HeaderUser isMobile={false} />
      </div>
      <div className='app-header-section-mobile'>
        <HeaderUser isMobile={true} />
      </div>
    </Header>
  );
};

export default AppHeader;

AppHeader.propTypes = {
  onToggleSidebar: PropTypes.func,
  isCollapsed: PropTypes.bool,
};
