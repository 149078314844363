import React from 'react';
import {RoutePermittedRole} from '../../shared/constants/AppEnums';

const Home = React.lazy(() => import('./Home'));

export const dashboardPageConfigs = [
  {
    permittedRole: RoutePermittedRole.user,
    path: '/dashboard/home',
    element: <Home />,
  },
];
