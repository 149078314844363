import React, {createContext, useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
} from '../../../../shared/constants/ActionTypes';
import jwtAxios, {setAuthToken} from './jwt-api';

const JWTAuthContext = createContext();
const JWTAuthActionsContext = createContext();

export const useJWTAuth = () => useContext(JWTAuthContext);

export const useJWTAuthActions = () => useContext(JWTAuthActionsContext);

const JWTAuthAuthProvider = ({children}) => {
  const [firebaseData, setJWTAuthData] = useState({
    user: null,
    isAuthenticated: false,
    isLoading: true,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const getAuthUser = () => {
      const token = localStorage.getItem('token');
      const storedName = localStorage.getItem('name');
      const storedRoleName = localStorage.getItem('role_name');
  
      if (!token) {
        setJWTAuthData({
          user: undefined,
          isLoading: false,
          isAuthenticated: false,
        });
        return;
      }
      setAuthToken(token);
      setJWTAuthData({
        user: {
          name: storedName,
          role_name: storedRoleName
        },
        isLoading: false,
        isAuthenticated: true
      });
    };
  
    getAuthUser();

    if (!localStorage.getItem('user') && window.location.pathname != '/signin') {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      localStorage.removeItem('role_name');
      window.location.href = '/signin';
    }
  }, []);

  const signInUser = ({ email, password }) => {
    dispatch({ type: FETCH_START });
  
    jwtAxios
      .post('/auth/login', { email, password })
      .then((response) => {
        const { data } = response;
        localStorage.setItem('token', data.data.token);
        localStorage.setItem('name', data.data.user.name);
        localStorage.setItem('role_name', data.data.user.role_name);
        localStorage.setItem('user', JSON.stringify(data.data.user));

        setAuthToken(data.data.token);
        setJWTAuthData({
          user: data.data.user,
          isAuthenticated: true,
          isLoading: false,
        });
        dispatch({ type: FETCH_SUCCESS });
      })
      .catch((error) => {
        setJWTAuthData({
          ...firebaseData,
          isAuthenticated: false,
          isLoading: false,
        });
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };

  const logout = async () => {
    dispatch({ type: FETCH_START });
  
    try {
      await jwtAxios
      .post('/auth/logout');
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      localStorage.removeItem('role_name');
      localStorage.removeItem('user');
      
      setAuthToken();
      setJWTAuthData({
        user: null,
        isLoading: false,
        isAuthenticated: false,
      });
      dispatch({ type: FETCH_SUCCESS });
    } catch (error) {
      setJWTAuthData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });
      dispatch({ type: FETCH_ERROR, payload: error.message });
    }
  };

  return (
    <JWTAuthContext.Provider
      value={{
        ...firebaseData,
      }}>
      <JWTAuthActionsContext.Provider
        value={{
          signInUser,
          logout,
        }}>
        {children}
      </JWTAuthActionsContext.Provider>
    </JWTAuthContext.Provider>
  );
};
export default JWTAuthAuthProvider;

JWTAuthAuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
