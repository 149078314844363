/* eslint-disable */
import React from 'react';
import {Spin} from 'antd';
import './index.style.less';

const AppSpinner = ({ children }) => {
  return (
    <div>
      {children}
      <div className='app-spinner' id='app-spinner'>
        <Spin size='large' />
      </div>
    </div>
  );
};

export default AppSpinner;
