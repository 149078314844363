import {authRole} from '../../../shared/constants/AppEnums';

export const getUserFromJwtAuth = (user) => {
  if (user)
    return {
      id: user.id,
      uid: user.id,
      displayName: user.name,
      email: user.email,
      role: authRole.user,
      displayRole: user.role_name
    };
  return user;
};
